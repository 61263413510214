<template>
  <div class="imei-storage-time">
    <KTCodePreview v-bind:title="'Thời gian lưu kho IMEI'">
      <template v-slot:preview>
        <b-row class="mb-4">
          <b-col md="3">
            <b-input
              size="sm"
              placeholder="Nhập tên, mã, mã vạch sản phẩm"
              v-model="searchProduct"
              v-on:keyup.enter="onFilter()"
            ></b-input>
          </b-col>
          <b-col md="3">
            <Autosuggest
              :model="selectedCateName"
              :suggestions="filteredCateOptions"
              placeholder="danh mục sản phẩm"
              @select="onSelectedCate"
              @change="onInputCateChange"
              suggestionName="suggestionName"
            />
          </b-col>
          <b-col md="3">
            <Autosuggest
              :model="selectedCate2Name"
              :suggestions="filteredCate2Options"
              placeholder="danh mục nội bộ"
              @select="onSelectedCate2"
              @change="onInputCate2Change"
              suggestionName="suggestionName"
            />
          </b-col>
          <b-col md="3">
            <b-row>
              <b-col class="col-md-4 text-title-input-form">
                <span>Tuổi( ngày):</span>
              </b-col>
              <b-col class="col-md-3 p-0">
                <b-form-select
                  class="select-style"
                  v-model="selectOperatorStorageTimeNum"
                  :options="listOperator"
                  size="sm"
                  value-field="id"
                  text-field="name"
                ></b-form-select>
              </b-col>
              <b-col class="col-md-5 p-0">
                <b-input
                  size="sm"
                  placeholder="Số ngày"
                  v-model="storageTimeNum"
                  @keypress="onlyNumber($event)"
                  :number="true"
                  class="text-right"
                ></b-input>
              </b-col>
            </b-row>
          </b-col>
        </b-row>
        <b-row class="mb-4">
          <b-col md="3">
            <BDropdownCustom
              v-model="value"
              :options="filterdOptions"
              :placeholder="'cửa hàng'"
              :matchKey="['name']"
              :searchable="true"
            />
          </b-col>
        </b-row>
        <b-row class="pl-0">
          <b-col md="10" class="pl-0">
            <b-button
              style="fontweight: 600; width: 70px"
              variant="primary"
              size="sm"
              @click="onFilter()"
              class="col-md-8 mb-4 ml-4"
              >Lọc</b-button
            >
          </b-col>
          <b-col>
            <div class="d-flex justify-content-end">
              <p
                class="mt-3 text-dark"
                style="font-weight: 500; font-size: 13px"
              >
                {{ fromNo }} - {{ toNo }} /
                {{ convertPrice(totalItem) }}
              </p>
            </div>
          </b-col>
        </b-row>
        <div>
          <b-table
            :items="listItem"
            :fields="fields"
            hover
            bordered
            :busy="onLoading"
          >
            <template v-slot:table-busy>
              <vcl-table :speed="5" :animate="true" :columns="8"></vcl-table>
            </template>
            <template v-slot:cell(productName)="row">
              <span
                v-if="row.item.barCode"
                v-text="row.item.barCode"
                style="white-space: normal; font-size: 12px; font-weight: 500"
              ></span>
              <p
                v-if="row.item.productCode"
                style="font-size: 12px; font-weight: 500; margin-bottom: 0"
              >
                ( {{ row.item.productCode }} )
              </p>
              <span
                v-text="row.item.productName"
                style="white-space: normal"
              ></span>
              <div></div>
            </template>
            <template v-slot:cell(statusName)="row">
              <span
                v-text="row.item.statusName"
                class="label font-weight-bold label-lg label-inline"
                v-bind:class="{
                  'label-light-warning': row.item.status === 1,
                  'label-light-success': row.item.status === 2,
                  'label-light-danger': row.item.status === 3,
                  'label-light-default': row.item.status === 4,
                }"
                style="width: max-content"
              ></span>
            </template>
          </b-table>
        </div>
        <b-row>
          <b-col>
            <p class="mt-3 text-dark" style="font-weight: 500">
              Tổng số :
              {{ convertPrice(totalItem) }}
            </p>
          </b-col>
          <b-col>
            <b-pagination-nav
              class="customPagination"
              v-if="numberOfPage >= 2"
              :link-gen="linkGen"
              :number-of-pages="numberOfPage"
              use-router
              @change="fetchData"
              align="right"
              first-class="page-item-first btn btn-icon btn-sm m-1"
              prev-class="page-item-prev btn btn-icon btn-sm m-1"
              next-class="page-item-next btn btn-icon btn-sm m-1 "
              last-class="page-item-last btn btn-icon btn-sm m-1 "
              page-class="btn btn-icon btn-sm border-0 m-1"
            >
              <template v-slot:first-text>
                <span>
                  <i class="ki ki-double-arrow-back icon-xs"></i>
                </span>
              </template>

              <template v-slot:prev-text>
                <i class="ki ki-arrow-back icon-xs"></i>
              </template>

              <template v-slot:next-text>
                <i class="ki ki-arrow-next icon-xs"></i>
              </template>

              <template v-slot:last-text>
                <span class="text-info">
                  <i class="ki ki-double-arrow-next icon-xs"></i>
                </span>
              </template>
            </b-pagination-nav>
          </b-col>
        </b-row>
      </template>
    </KTCodePreview>
  </div>
</template>

<style lang="scss">
.imei-storage-time {
  .text-title-input-form {
    display: flex;
    align-items: center;
    font-weight: 500;
  }
  .d-flex {
    display: -webkit-box !important;
  }
  .text-title {
    font-weight: 600;
    font-size: 16px;
  }
  .text-lable-total {
    font-weight: 600;
  }
  .text-report {
    font-weight: 500;
    font-size: 13px;
  }
  .labelInput {
    font-weight: 600;
  }
  .productCode:hover {
    text-decoration: underline;
  }

  .inputText {
    width: 22%;
  }
  .btn.btn-icon.btn-sm,
  .btn-group-sm > .btn.btn-icon {
    height: calc(1.35em + 1.1rem + 2px);
    width: auto;
  }

  .icon:hover {
    background-color: #90c6fc;
  }

  .dropdown-header {
    color: #3699ff !important;
  }
  .table th,
  .table td {
    padding: 0.75rem;
    vertical-align: middle;
    border-top: 1px solid #ecf0f3;
  }
  .table.b-table.table-sm
    > thead
    > tr
    > [aria-sort]:not(.b-table-sort-icon-left),
  .table.b-table.table-sm
    > tfoot
    > tr
    > [aria-sort]:not(.b-table-sort-icon-left) {
    background-position: right calc(1.5rem / 2) center;
    padding-right: calc(0.3rem + 0.65em);
  }
  .imeiCode:hover {
    text-decoration: underline;
  }
  .page-item.disabled {
    cursor: not-allowed;
    &.bv-d-xs-down-none {
      .page-link {
        margin: 0.35rem 0.25rem;
        text-align: center;
      }
    }
  }
}
</style>

<script>
import KTCodePreview from '@/view/content/CodePreview.vue';
import { SET_BREADCRUMB } from '@/core/services/store/modules/breadcrumbs.module';
import ApiService from '@/core/services/api.service';
import localData from '@/utils/saveDataToLocal';
import { VclTable } from 'vue-content-loading';
import { removeAccents } from '@/utils/common';
import { cloneDeep, map, find, assign } from 'lodash';
import { convertPrice } from '@/utils/common';
import Autosuggest from '@/view/base/auto-sugguest/AutoSuggest.vue';
import BDropdownCustom from '@/view/base/bootstrap/BDropdownCustom.vue';

export default {
  data() {
    return {
      fields: [
        {
          key: 'productName',
          label: 'Sản phẩm',
          thStyle: {
            textAlign: 'center',
            color: 'rgb(24, 28, 50)',
            width: '25%',
          },
        },
        {
          key: 'imeiNo',
          label: 'IMEI',
          thStyle: {
            textAlign: 'center',
            color: 'rgb(24, 28, 50)',
            width: '15%',
          },
        },
        {
          key: 'storeName',
          label: 'Kho',
          thStyle: {
            textAlign: 'center',
            color: 'rgb(24, 28, 50)',
            width: '10%',
          },
        },
        {
          key: 'statusName',
          label: 'Trạng thái',
          thStyle: {
            textAlign: 'center',
            color: 'rgb(24, 28, 50)',
            width: '10%',
          },
          tdClass: 'text-center text-report',
        },
        {
          key: 'importDate',
          label: 'Ngày bắt đầu nhập kho hệ thống',
          thStyle: {
            textAlign: 'center',
            color: 'rgb(24, 28, 50)',
            width: '15%',
          },
          tdClass: 'text-right text-report',
        },
        {
          key: 'storageTimeNum',
          label: 'Tuổi kho hệ thống',
          thStyle: {
            textAlign: 'center',
            color: 'rgb(24, 28, 50)',
            width: '5%',
          },
          tdClass: 'text-right text-report',
        },
        {
          key: 'receiptDate',
          label: 'Ngày bắt đầu nhập kho',
          thStyle: {
            textAlign: 'center',
            color: 'rgb(24, 28, 50)',
            width: '15%',
          },
          tdClass: 'text-right text-report',
        },
        {
          key: 'receiptDateNum',
          label: 'Tuổi kho',
          thStyle: {
            textAlign: 'center',
            color: 'rgb(24, 28, 50)',
            width: '5%',
          },
          tdClass: 'text-right text-report',
        },
      ],
      linkGen: (pageNum) => {
        return pageNum === 1 ? '?' : `?page=${pageNum}`;
      },
      page: 1,
      totalItem: 0,
      onLoading: false,
      numberOfPage: 1,
      filteredOptions: [],
      searchProduct: '',
      value: [],
      options: [],
      cateOptions: [
        {
          data: [],
        },
      ],
      filteredCateOptions: [],
      selectedCateId: null,
      selectedCateName: '',
      cate2Options: [
        {
          data: [],
        },
      ],
      filteredCate2Options: [],
      selectedCate2Id: null,
      selectedCate2Name: '',
      fromNo: 0,
      toNo: 0,
      LIMIT_PAGE: 30,
      selectOperatorStorageTimeNum: 5,
      listOperator: [
        {
          id: 5,
          name: '>=',
        },
        {
          id: 4,
          name: '<=',
        },
      ],
      listItem: [],
      filterdOptions: [],
      storageTimeNum: 0,
    };
  },
  components: {
    KTCodePreview,
    VclTable,
    Autosuggest,
    BDropdownCustom,
  },
  computed: {},
  mounted() {
    this.$store.dispatch(SET_BREADCRUMB, [
      { title: 'Thời gian lưu kho IMEI', route: '/imei-storage-time' },
      { title: 'Thời gian lưu kho IMEI' },
    ]);
    this.fetchStoreByUser();
  },
  created() {
    let imeiCode = this.$route.query.code;
    let searchProduct = this.$route.query.searchProduct;
    if (imeiCode !== undefined) {
      this.search = imeiCode;
    }
    if (searchProduct !== undefined) {
      this.searchProduct = searchProduct;
    }
    this.fetchCategory();
    this.fetchInternalCategory();
  },
  methods: {
    convertPrice,
    onlyNumber(evt) {
      const keysAllowed = ['0', '1', '2', '3', '4', '5', '6', '7', '8', '9'];
      const keyPressed = evt.key;
      if (!keysAllowed.includes(keyPressed)) {
        evt.preventDefault();
      } else {
        return true;
      }
    },
    fetchData: function () {
      this.page = this.$route.query.page;
      if (!this.page) {
        this.page = 1;
      }
      let listId = [];
      if (this.value.length) {
        listId = this.value.map(({ code }) => code);
      }
      this.filterdOptions = cloneDeep(this.options);
      if (this.selectedCateName.trim() === '') {
        this.selectedCateId = null;
      }
      if (this.selectedCate2Name.trim() === '') {
        this.selectedCate2Id = null;
      }

      const param = {
        page: this.page,
        limit: this.LIMIT_PAGE,
        searchProduct: this.searchProduct,
        storeIds: listId,
        categoryId: this.selectedCateId,
        internalCategoryId: this.selectedCate2Id,
        storageTimeNum: this.storageTimeNum,
        operatorStorageTimeNum: this.selectOperatorStorageTimeNum,
      };

      const paramQuery = {
        params: param,
      };

      this.onLoading = true;
      this.listItem = [];
      ApiService.query('storage-time-reports/by-imei', paramQuery).then(
        ({ data }) => {
          this.listItem = data.data.dataset;
          this.numberOfPage = data.data.totalPage;
          this.totalItem = data.data.totalRow;
          this.onLoading = false;
          this.fromNo = (this.page - 1) * this.LIMIT_PAGE + 1;
          this.toNo = this.LIMIT_PAGE * this.page;
          if (this.toNo > this.totalItem) {
            this.toNo = this.totalItem;
          }
        },
      );
    },
    checkPermission: function (condition) {
      return localData.checkPermission(condition);
    },
    onSelect(option) {
      const index = this.options.findIndex((item) => item.name == option.name);
      if (index !== -1) this.options[index].checked = true;
      this.filterdOptions = cloneDeep(this.options);
    },

    onRemove(option) {
      let index = this.options.findIndex((item) => item.name == option.name);
      this.options[index].checked = false;
      this.filterdOptions = cloneDeep(this.options);
    },
    customLabel(option) {
      return `${option.name}`;
    },
    onFilter() {
      this.$route.query.page = 1;
      this.$router.push({
        name: 'list-imei-storage-time',
      });
      this.fetchData();
    },
    fetchCategory: function () {
      const param = {
        page: 1,
        limit: 1000,
        name: '',
        code: '',
      };
      const paramQuery = {
        params: param,
      };
      ApiService.query('category', paramQuery).then(({ data }) => {
        this.cateOptions[0].data = [];
        data.data.list_caterogy.forEach((element) => {
          let name = '';
          if (element.level === 1) {
            name = '- ' + element.name;
          } else if (element.level === 2) {
            name = '- - ' + element.name;
          } else if (element.level === 3) {
            name = '- - - ' + element.name;
          } else if (element.level === 4) {
            name = '- - - - ' + element.name;
          } else if (element.level === 5) {
            name = '- - - - - ' + element.name;
          } else if (element.level === 6) {
            name = '- - - - - - ' + element.name;
          } else {
            name = element.name;
          }
          let item = {
            id: element.id,
            suggestionName: name,
            name: element.name,
          };
          this.cateOptions[0].data.push(item);
        });

        this.filteredCateOptions = [...this.cateOptions[0].data];
      });
    },
    fetchInternalCategory: function () {
      const param = {
        page: 1,
        limit: 1000,
        name: '',
        code: '',
      };
      const paramQuery = {
        params: param,
      };
      ApiService.query('productExternalCategory/get-all', paramQuery).then(
        ({ data }) => {
          this.cate2Options[0].data = [];
          data.data.forEach((element) => {
            let name = '';
            if (element.level === 1) {
              name = '- ' + element.name;
            } else if (element.level === 2) {
              name = '- - ' + element.name;
            } else if (element.level === 3) {
              name = '- - - ' + element.name;
            } else if (element.level === 4) {
              name = '- - - - ' + element.name;
            } else if (element.level === 5) {
              name = '- - - - - ' + element.name;
            } else if (element.level === 6) {
              name = '- - - - - - ' + element.name;
            } else {
              name = element.name;
            }
            const item = {
              id: element.id,
              suggestionName: name,
              name: element.name,
            };
            this.cate2Options[0].data.push(item);
          });
          this.filteredCate2Options = [...this.cate2Options[0].data];
        },
      );
    },
    onSelectedCate(option) {
      this.selectedCateId = option.item.id;
      this.selectedCateName = option.item.name;
    },
    onInputCateChange(text) {
      this.selectedCateName = text;

      const filteredData = this.cateOptions[0].data.filter((item) => {
        return (
          removeAccents(item.name)
            .toLowerCase()
            .indexOf(removeAccents(text.toLowerCase())) > -1
        );
      });

      this.filteredCateOptions = [...filteredData];
    },
    onSelectedCate2(option) {
      this.selectedCate2Id = option.item.id;
      this.selectedCate2Name = option.item.name;
    },
    onInputCate2Change(text) {
      this.selectedCate2Name = text;

      const filteredData = this.cate2Options[0].data.filter((item) => {
        return (
          removeAccents(item.name)
            .toLowerCase()
            .indexOf(removeAccents(text.toLowerCase())) > -1
        );
      });

      this.filteredCate2Options = [...filteredData];
    },
    fetchStoreByUser() {
      ApiService.get(`stores/get-by-user`).then(({ data }) => {
        if (data.status === 1) {
          this.options = [];
          const stores = data.data.stores;
          stores.forEach((element) => {
            let option = {
              code: element.id,
              name: element.shortName,
              checked: false,
            };
            this.options.push(option);
            if (!data.data.viewAll) {
              let index = this.options.findIndex(
                (item) => item.code == element.id,
              );
              this.options[index].checked = true;
              this.value.push(this.options[index]);
            }
          });
          this.filterdOptions = cloneDeep(this.options);
        }
        this.fetchData();
      });
    },
    onInputed(textInput = '') {
      this.searchStore(textInput);
    },
    searchStore(textInput) {
      let options = cloneDeep(this.options);
      if (!textInput || !textInput.trim().length) {
        this.filterdOptions = map(options, (obj) => {
          return assign(obj, find(this.filterdOptions, { id: obj.id }));
        });
        return;
      }

      const indexChooseAll = options.findIndex((prop) => prop.id === -1);

      if (indexChooseAll > -1) {
        options.splice(indexChooseAll, 1);
      }

      options = map(options, (obj) => {
        return assign(obj, find(this.filterdOptions, { id: obj.id }));
      });

      this.filterdOptions = this.fitlerOptionsBy(
        options,
        textInput,
        'name',
        10,
      );
    },
    fitlerOptionsBy(items, textInput, prop, limit) {
      return cloneDeep(items)
        .filter((item) => {
          if (item) {
            const nameWTUnicode = removeAccents(item[prop] || '');
            const nameInputWTUnicode = removeAccents(textInput);
            const index = nameWTUnicode
              .toLowerCase()
              .indexOf(nameInputWTUnicode.toLowerCase());

            if (index > -1) {
              return true;
            }
          }
          return false;
        })
        .slice(0, limit);
    },
  },
};
</script>
